const NUMERALS = [
	'I',
	'II',
	'III',
	'IV',
	'V',
	'VI',
	'VII',
	'VIII',
	'IX',
	'X',
	'XI',
	'XII',
	'XIII',
	'XIV',
	'XV',
]

export const addRomanNumeralToText = (text: string, idx: number) => {
	const numIdx = idx - 1
	if (idx === 0 || numIdx > NUMERALS.length - 1) {
		return text
	}
	return NUMERALS[numIdx].concat('. ', text)
}
