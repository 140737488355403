import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SideMenuLink } from 'service'

export const useNestablePageNavigation = (
	currentPageOrder: number,
	sideMenuLinks: Array<SideMenuLink>
) => {
	const { t } = useTranslation()

	const { previousStep, nextStep } = useMemo(() => {
		let previousStep = {
			slug: sideMenuLinks[0].fullSlug,
			label: t('Back to main page'),
		}
		let nextStep = previousStep

		const previousPageOrder = currentPageOrder - 1
		const nextPageOrder = currentPageOrder + 1
		const previousPage = sideMenuLinks[previousPageOrder]
		const nextPage = sideMenuLinks[nextPageOrder]

		if (sideMenuLinks.length === 2) {
			return { previousStep, nextStep }
		}
		if (previousPageOrder === 0) {
			nextStep = {
				slug: nextPage.fullSlug,
				label: t('Next'),
			}
		} else if (nextPageOrder === sideMenuLinks.length) {
			previousStep = {
				slug: previousPage.fullSlug,
				label: t('Previous'),
			}
		} else {
			previousStep = {
				slug: previousPage.fullSlug,
				label: t('Previous'),
			}
			nextStep = {
				slug: nextPage.fullSlug,
				label: t('Next'),
			}
		}
		return { previousStep, nextStep }
	}, [currentPageOrder, sideMenuLinks, t])

	return { previousStep, nextStep }
}
