import { useNestablePageNavigation } from './useNestablePageNavigation'
import { SideMenuLink } from 'service'
import { ArrowLeft, ArrowRight, Button } from 'ui'

export interface NavigationButtonProps {
	order: number
	sideMenuLinks: Array<SideMenuLink>
}

const NavigationButtons = ({ order, sideMenuLinks }: NavigationButtonProps) => {
	const { previousStep, nextStep } = useNestablePageNavigation(
		order,
		sideMenuLinks
	)

	return (
		<div className="flex h-auto w-full gap-2 sm:flex-col">
			<Button variant="secondary" icon={<ArrowLeft />} href={previousStep.slug}>
				{previousStep.label}
			</Button>
			{sideMenuLinks.length > 2 && (
				<Button
					variant="secondary"
					icon={<ArrowRight />}
					iconPosition="right"
					href={nextStep.slug}
				>
					{nextStep.label}
				</Button>
			)}
		</div>
	)
}

export default NavigationButtons
